<template>
    <div>
        <div class="step-content">
            <div class="step-form-item">
                <div class="step-form-item-label">问卷名称</div>
                <div>
                    <input
                        type="text"
                        maxlength="20"
                        placeholder="限20个字"
                        v-model="name"
                    />
                </div>
            </div>
            <div class="step-form-item">
                <div class="step-form-item-label">问卷说明</div>
                <div>
                    <textarea
                        placeholder="限100个字"
                        maxlength="100"
                        v-model="description"
                    ></textarea>
                </div>
            </div>
            <div class="questions-content">
                <div class="step-form-item">
                    <div class="step-form-item-label">题目</div>
                    <div class="questions-content-form">
                        <div
                            class="questions-content-form-item"
                            v-for="(item, qestionIndex) in questions"
                            :key="qestionIndex"
                        >
                            <div class="questions-content-form-item-label">
                                {{ qestionIndex + 1 }}.题目描述
                            </div>
                            <div class="questions-content-form-item-content">
                                <div>
                                    <input
                                        style="width: 400px"
                                        type="text"
                                        placeholder="限100个字"
                                        v-model="item.question"
                                        maxlength="100"
                                    />
                                    <svg class="icon ml-3" aria-hidden="true" v-if="questions.length > 1"
                                        @click="questions.splice(qestionIndex, 1)"
                                    >
                                        <use xlink:href="#icon-menushanchu"></use>
                                    </svg>
                                </div>
                                <div>
                                    <label
                                        for=""
                                        v-for="(
                                            typeName, typeKey
                                        ) in questionsType"
                                        :key="typeKey"
                                        @click="
                                            changeQuestionType(item, typeKey)
                                        "
                                    >
                                        <input
                                            type="radio"
                                            :value="typeKey"
                                            v-model="item.type"
                                        />
                                        {{ typeName }}
                                    </label>
                                </div>
                                <template
                                    v-if="item.type != QUESTION_TYPE.FILL_BLANK"
                                >
                                    <div
                                        v-for="(
                                            option, optionIndex
                                        ) in item.options"
                                        :key="optionIndex"
                                        class="align-center"
                                    >
                                        选项
                                        <input
                                            style="
                                                width: 332px;
                                                margin-left: 20px;
                                            "
                                            type="text"
                                            placeholder="限20个字"
                                            v-model="item.options[optionIndex]"
                                        />
                                        <svg class="icon ml-3" aria-hidden="true" v-if="item.options.length > 1"
                                            @click="item.options.splice(optionIndex,1)"
                                        >
                                            <use xlink:href="#icon-menushanchu"></use>
                                        </svg>
                                    </div>
                                    <div>
                                        <button
                                            @click="addQuestionOptions(item)"
                                        >
                                            <i class="el-icon-plus"></i>添加选项
                                        </button>
                                    </div>
                                </template>
                            </div>
                        </div>

                        <div class="questions-content-form-item">
                            <button @click="addQuestion">
                                <i class="el-icon-plus"></i>添加题目
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <slot name="footer"></slot>
    </div>
</template>
<script>
import { QUESTION_TYPE } from "@/constant";

export default {
    name: "addQuestionnaireSecond",
    data() {
        return {
            QUESTION_TYPE,
            questionsType: {
                [QUESTION_TYPE.RADIO]: "单选",
                [QUESTION_TYPE.MULTIPLE_SELECTION]: "多选",
                [QUESTION_TYPE.FILL_BLANK]: "填空题",
            },
            name: "",
            description: "",
            questions: [
                {
                    question: "",
                    type: QUESTION_TYPE.RADIO,
                    options: [""],
                    order: null,
                },
            ],
        };
    },
    methods: {
        /**
         * 更改问题类型
         * @param {Object} question 问题
         * @param {Number} type 问题类型 参考:QUESTION_TYPE
         *  */
        changeQuestionType(question, type) {
            question.type = type;
            if (type == QUESTION_TYPE.FILL_BLANK) {
                question.options = "";
            } else {
                question.options = [""];
            }
        },
        // 添加问题条目
        addQuestion() {
            this.questions.push({
                question: "",
                type: QUESTION_TYPE.RADIO,
                options: [""],
                order: null,
            });
        },
        /**
         * 添加问题选项
         * @param {Object} question 问题
         *  */
        addQuestionOptions(question) {
            question.options.push("");
        },
        returnDataObj() {
            const { name, description, questions: tempQuestion } = this;
            let questions = tempQuestion.map((question, key) => {
                const tempQuestion = { ...question };
                tempQuestion.order = key + 1;
                tempQuestion.type = tempQuestion.type.toString();
                tempQuestion.options = JSON.stringify(tempQuestion.options);
                return JSON.stringify(tempQuestion);
            });
            return {
                name,
                description,
                questions,
            };
        },
    },
};
</script>
<style lang="stylus" scoped>
@import './questionnaireStep.styl'

/* .step-content
max-height 460px
overflow-y auto */
.step-form-item
    input[type='text']
        height 40px
        border-radius 4px
        border 1px solid #979797
        padding 10px
        box-sizing border-box
        outline none
    textarea
        width 720px
        height 200px
        background #F0F0F0
        border-radius 4px
        padding 10px
        box-sizing border-box
        outline none
        border none
        resize none
.questions-content
    border-top 1px solid #eee
    padding-top 30px
    .questions-content-form
        &-item
            display flex
            flex-flow row nowrap
            &:not(:last-of-type)
                margin-bottom 26px
            button
                width 144px
                height 40px
                background #1ab394
                box-shadow 0 5px 10px 0 rgba(0, 182, 148, 0.3)
                border-radius 4px
                color #fff
                border none
                font-size 24px
            &-label
                margin-right 40px
                text-align right
                width 120px
            &-content
                div
                    &:not(:last-of-type)
                        margin-bottom 10px
</style>