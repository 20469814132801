<template>
    <div>
         <CSBreadcrumb style="overflow: hidden"></CSBreadcrumb>
        <div class="nav-bar" v-if="step > 0">
                <div @click="prevStep" style="cursor: pointer">
                    <i class="el-icon-arrow-left"></i>
                    <div>返回上一步</div>
                </div>
        </div>
        <div class="questionnaire-content">
            <AddQuestionnaireFirstStep v-show="step === 0" ref="firstStep">
                <template v-slot:footer>
                    <div class="step-footer">
                        <button @click="nextStep">下一步</button>
                    </div>
                </template>
            </AddQuestionnaireFirstStep>
            <AddQuestionnaireSecondStep v-show="step === 1" ref="secondStep">
                <template v-slot:footer>
                    <div class="step-footer">
                        <button @click="nextStep">预览</button>
                    </div>
                </template>
            </AddQuestionnaireSecondStep>
            <QuestionnairePreview v-show="step === 2" :data="previewData">
                <template v-slot:footer>
                    <div class="step-footer">
                        <button :class="{disabled: isSubmitting}" @click="commitQuestionnaire">提交</button>
                    </div>
                </template>
            </QuestionnairePreview>
        </div>
    </div>
</template>
<script>
import AddQuestionnaireFirstStep from "@/components/Questionnaire/AddQuestionnaireFirstStep";
import AddQuestionnaireSecondStep from "@/components/Questionnaire/AddQuestionnaireSecondStep";
import QuestionnairePreview from "@/components/phonePreview/QuestionnairePreview";
import { addSurveyUrl } from "@/requestUrl";
import { cloneDeep } from "lodash";
import { QUESTION_TYPE } from "@/constant";
import CSBreadcrumb from "@/components/common/CSBreadcrumb";
export default {
    name: "addQuestionnair",
    components: {
        AddQuestionnaireFirstStep,
        AddQuestionnaireSecondStep,
        QuestionnairePreview,
        CSBreadcrumb
    },
    data() {
        return {
            isSubmitting: false,
            // 添加问卷步数，从0开始
            step: 0,
            name: "", //问卷名称 必填
            description: "", //问卷说明 必填
            sendType: [], //发送对象 必填 1.楼内用户;2.访客(含未知身份);3.企业对接人4.业主;5.普通员工;6.部门主管;7.物业经理
            condition: {},
            type: 1, //发送条件的类型 0.无;1.行为;2.定时发送;3.按注册时间发送;4.按合同签约时间发送
            validDays: 1, //有效期 必填
            questions: [],

            previewData: {},
            newUserRegister:"",
            endTime:""
        };
    },
    methods: {
        // 提交问题
        commitQuestionnaire() {
            console.log(this)
            if (this.isSubmitting) {
                this.$vc.message('请不要重复提交');
                return ;
            }
            const {
                name,
                description,
                sendType,
                condition,
                type,
                validDays,
                questions,
                newUserRegister,
                endTime
            } = this;
            this.isSubmitting = true;
            this.$fly
                .post(addSurveyUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    name,
                    description,
                    sendType: JSON.stringify(sendType),
                    condition: JSON.stringify(condition),
                    type,
                    validDays,
                    questions: `[${questions.toString()}]`,
                    newUserRegister,
                    endTime
                })
                .then((res) => {
                    if (res.code !== 0) {
                        return;
                    }
                    this.$vc.toast("添加成功");
                    this.$router.push({name: 'questionnaireManage'});
                })
                .finally(() => this.isSubmitting = false);
        },
        // 下一步
        nextStep() {
            const step = this.step + 1;
            let stepData;

            if(step == 1){
                stepData = this.$refs.firstStep.returnDataObj();
                this.$vc.copyObject(stepData, this);
            }
            if(step == 2){
                stepData = this.$refs.secondStep.returnDataObj();
                    this.$vc.copyObject(stepData, this);
            }
            const {
                name,
                sendType,
                description,
                condition,
                type,
                validDays,
                questions,
                newUserRegister,
                endTime
            } = this;
            switch (step) {
                case 1:
                    stepData = this.$refs.firstStep.returnDataObj();
                    console.log(222)
                    console.log(stepData)
                    this.$vc.copyObject(stepData, this);
                    if (sendType.length === 0) {
                        this.$vc.toast('请选择身份');
                        return;
                    }
                    if (type === 0 && newUserRegister === 1) {
                        if (!endTime){
                            this.$vc.toast('请选择停止发送时间');
                            return;
                        }
                    }
                    break;
                case 2:
                    stepData = this.$refs.secondStep.returnDataObj();
                    this.$vc.copyObject(stepData, this);

                    if (name === undefined || name === '') {
                        this.$vc.toast('请填写问卷名称');
                        return;
                    } else if (description === undefined || description === '') {
                        this.$vc.toast('请填写问卷说明');
                        return;
                    } else if (questions.length > 0) {
                        let state = false;
                        for(let item of questions) {
                            let itemQues = JSON.parse(item);
                            if (!itemQues.question) {
                                this.$vc.toast('请填写题目描述');
                                state = true;
                                break;
                            } else if (itemQues.type != QUESTION_TYPE.FILL_BLANK && JSON.parse(itemQues.options).length < 2) {
                                this.$vc.toast('请填写2个以上的选项');
                                state = true;
                                break;
                            }
                        }
                        if (state) {
                            return;
                        }
                    }
                    this.previewData = cloneDeep({
                        name,
                        description,
                        sendType,
                        condition,
                        type,
                        validDays,
                        questions,
                        newUserRegister,
                        endTime
                    });
                    this.previewData.type = JSON.parse(this.previewData.type);
                    this.previewData.questions = this.previewData.questions.map(
                        (question) => {
                            question = JSON.parse(question);
                            if (question.type != QUESTION_TYPE.FILL_BLANK) {
                                question.options = JSON.parse(question.options);
                            }
                            return question;
                        }
                    );
                    break;
            }
            this.step = step;
        },
        // 上一步
        prevStep() {
            if (this.step) {
                this.step--;
            }
        },
    },
};
</script>
<style lang="stylus" scoped>
.nav-bar
    width 100%
    // height 60px
    background #FFFFFF
    box-shadow 0px 5px 10px 0px rgba(0, 0, 0, 0.1)
    font-size 24px
    color var(--pc-theme-color)
    padding 18px 30px
    box-sizing border-box
    div
        line-height 24px
        display inline-block
.questionnaire-content
    margin 20px 30px
    background #FFFFFF
    box-shadow 0 5px 10px 0 rgba(0, 0, 0, 0.1)
</style>
